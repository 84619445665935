<template>
  <div class="sensor-list-container">
    <h2>Sensor List</h2>
    <router-link :to="{ name: 'sensorAdd' }">
      <button class="add-sensor-btn">Add New Sensor</button>
    </router-link>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="sensors.length > 0" class="sensor-table">
        <thead>
        <tr>
          <th>Sensor ID</th>
          <th>Vendor</th>
          <th>Assigned</th> <!-- New column header -->
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="sensor in sensors" :key="sensor.id">
          <td>{{ sensor.sensorId }}</td>
          <td>{{ vendorNames[sensor.vendorId] || 'Loading...' }}</td> <!-- Updated to show vendor name -->
          <td>{{ sensor.animalId ? 'YES' : 'NO' }}</td> <!-- New column data -->
          <td>
            <button @click="viewSensor(sensor.id)">View</button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else class="no-sensors-message">There are no sensors matching the selected filters.</p>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, DEFAULT_LIST_LIMIT, EGettersType, EModificationType, ESensorSortType, ESortOrderType } from '../globals';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
      vendorNames: {} as Record<string, string> // Define the type of vendorNames
    };
  },
  created: function () {
    this.fetchSensors();
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_SENSORS
    ])
  },
  methods: {
    fetchSensors() {
      this.isLoading = true;

      this.$store.dispatch(EModificationType.FETCH_SENSORS, {
        sortOrder: ESortOrderType.ASC,
        sortBy: ESensorSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage
      }).then((count) => {
        this.totalDocumentCount = count;
        this.isLoading = false;
        if (this.sensors.length === 0) {
          this.currentPage = 1;
        } else {
          this.sensors.forEach((sensor: any) => {
            this.fetchVendorName(sensor.vendorId);
          });
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    fetchVendorName(vendorId: string) {
      if (!this.vendorNames[vendorId]) { // Check if vendor name is already fetched
        this.$store.dispatch('FETCH_VENDOR_BY_ID', { vendorId })
          .then((vendor: any) => {
            this.$set(this.vendorNames, vendorId, vendor.name);
          })
          .catch(e => {
            console.log('Error fetching vendor name: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
          });
      }
    },
    viewSensor(sensorId: string) {
      this.$router.push({ name: 'sensorView', params: { id: sensorId } });
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchSensors();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchSensors();
      }
    }
  }
});
</script>

<style scoped>
.sensor-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.add-sensor-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-sensor-btn:hover {
  background-color: #1db674;
}

.sensor-table {
  width: 100%;
  border-collapse: collapse;
}

.sensor-table th,
.sensor-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.sensor-table th {
  background-color: #f4f4f4;
}

.loading {
  
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-sensors-message {
  
  margin: 20px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
