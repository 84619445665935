<template>
  <div>
    <h1>Sensors</h1>
    <SensorList />
  </div>
</template>

<script>
import SensorList from '../components/SensorList.vue';

export default {
  name: 'SensorsView',
  components: {
    SensorList,
  },
};
</script>