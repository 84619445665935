<template>
  <div>
    <h1>Add New Vendor</h1>
    <form @submit.prevent="handleSubmit" class="vendor-form">
      <div class="form-group">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="vendor.name" required>
      </div>
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="vendor.email" required>
      </div>
      <div class="form-group">
        <label for="phone">Phone:</label>
        <input type="text" id="phone" v-model="vendor.phone" required>
      </div>
      <button type="submit" class="add-vendor-btn">Add Vendor</button>
    </form>
  </div>
</template>

<script lang="ts">
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      vendor: {
        name: '',
        email: '',
        phone: '',
      },
    };
  },
  methods: {
    handleSubmit: async function () {
      try {
        await this.$store.dispatch(EModificationType.CREATE_VENDOR, { name: this.vendor.name, email: this.vendor.email, phone: this.vendor.phone  });
        this.$router.push({ name: 'vendors' }); // Navigate back to vendors list
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
  },
});
</script>

<style scoped>
.vendor-form {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Adjust as needed */
  margin: auto; /* Center the form horizontally */
}

.form-group {
  margin-bottom: 1rem; /* Adjust spacing between form groups */
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"] {
  width: 100%; /* Full-width input fields */
  padding: 10px; /* Consistent padding */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-vendor-btn {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-vendor-btn:hover {
  background-color: #0056b3;
}
</style>
