<template>
  <div class="vendor-list-container">
    <h2>Vendor List</h2>
    <router-link :to="{ name: 'vendorAdd' }">
      <button class="add-vendor-btn">Add New Vendor</button>
    </router-link>
    <div v-if="isLoading" class="loading">Loading...</div>
    <div v-else>
      <table v-if="vendors.length > 0" class="vendor-table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Phone</th>
          <th>Email</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="vendor in vendors" :key="vendor.id">
          <td>
            <div class="vendor-info">
              <div>
                <p class="vendor-name">{{ vendor.name }}</p>
              </div>
            </div>
          </td>
          <td>{{ vendor.phone }}</td>
          <td>{{ vendor.email }}</td>
          <td>
            <button @click="viewVendor(vendor.id)">View</button>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else class="no-vendors-message">There are no vendors matching the selected filters.</p>
      <div class="pagination">
        <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage * itemsPerPage >= totalDocumentCount">Next</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType, DEFAULT_LIST_LIMIT, ESortOrderType, EVendorSortType, EGettersType } from '../globals';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalDocumentCount: 0,
      itemsPerPage: DEFAULT_LIST_LIMIT,
    };
  },
  created() {
    this.fetchVendors();
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_VENDORS
    ])
  },
  methods: {
    fetchVendors() {
      this.isLoading = true;

      this.$store.dispatch(EModificationType.FETCH_VENDORS, {
        sortOrder: ESortOrderType.ASC,
        sortBy: EVendorSortType.DATE,
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage
      }).then((count) => {
        this.totalDocumentCount = count;
        this.isLoading = false;
        if (this.vendors.length === 0) {
          this.currentPage = 1;
        }
      }).catch(e => {
        this.isLoading = false;
        console.log('Show on interface: ', e.message || DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      });
    },
    viewVendor(vendorId: string) {
      this.$router.push({ name: 'vendorView', params: { id: vendorId } });
    },
    nextPage() {
      if (this.currentPage * this.itemsPerPage < this.totalDocumentCount) {
        this.currentPage++;
        this.fetchVendors();
      }
    },
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchVendors();
      }
    }
  }
});
</script>

<style scoped>
.vendor-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.add-vendor-btn {
  
  padding: 10px 20px;
  font-size: 16px;
  background-color: #2dce89;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.add-vendor-btn:hover {
  background-color: #1db674;
}

.vendor-table {
  width: 100%;
  border-collapse: collapse;
}

.vendor-table th,
.vendor-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #666666;
}

.vendor-table th {
  background-color: #f4f4f4;
}

.vendor-info {
  display: flex;
  align-items: center;
}

.vendor-name {
  
  font-weight: bold;
}

.loading {
  
  font-size: 18px;
  text-align: center;
  margin: 20px 0;
}

.no-vendors-message {
  
  margin: 20px 0;
  font-size: 18px;
  text-align: center;
  color: #888;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
}
</style>
