<template>
  <div class="chart-container">
    <line-chart :data="chartData" :options="chartOptions"></line-chart>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, TimeScale, LinearScale } from 'chart.js';
import 'chartjs-adapter-moment';
import { EFetchVitalFrequency } from '../globals';

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, TimeScale, LinearScale);

export default Vue.extend({
  components: { 'line-chart': Line },
  data() {
    return {
    };
  },
  computed: {
    chartOptions: function () {
      const baseOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: this.frequency === (EFetchVitalFrequency.DAILY as string) ? 'day' : 'hour'
            },
            title: {
              display: true,
              text: 'Recorded Date'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Value'
            },
            min: this.bounds.lower,
            max: this.bounds.upper
          }
        }
      };

      return baseOptions;
    },
    chartData: function () {
      return {
        labels: this.labels,
        datasets: [{
          label: this.label,
          data: this.data,
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1
        }]
      }
    },
  },
  props: {
    labels: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    frequency: {
      type: String,
      required: true
    },
    bounds: {
      type: Object,
      required: true
    }
  }
});
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 40vh;
  width: 80vw;
}
</style>
