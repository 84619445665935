<template>
  <div class="vendor-view-container" v-if="vendor">
    <h2>Vendor Details</h2>
    <div class="vendor-details">
      <p><strong>Name:</strong> {{ vendor.name }}</p>
      <p><strong>Phone:</strong> {{ vendor.phone }}</p>
      <p><strong>Email:</strong> {{ vendor.email }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';

export default Vue.extend({
  async created() {
    try {
      await this.fetchVendor();
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  data() {
    return {
      vendor: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  methods: {
    async fetchVendor() {
      try {
        this.vendor = await this.$store.dispatch(EModificationType.FETCH_VENDOR_BY_ID, { vendorId: this.id });
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.vendor-view-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.vendor-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.back-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
