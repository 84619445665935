/**
 * Gets the access token
 */
export function getAccessToken(): string {
  const token = localStorage.getItem('token');
  return token ? token : "";
}

/**
 * Sets the access token and isAuthed
 * @param token
 */
export function setAccessToken(token: string): void {
  localStorage.setItem('token', token);
  return;
}

/**
 * Removes settings upon logout
 * TODO: Clear all cache on logout
 */
export function logUserOut(): void {
  localStorage.removeItem('token');
  return;
}