<template>
  <div class="user-view-container" v-if="user">
    <h2>User Details</h2>
    <div class="user-details">
      <p><strong>First Name:</strong> {{ user.firstName }}</p>
      <p><strong>Last Name:</strong> {{ user.lastName }}</p>
      <p><strong>Email:</strong> {{ user.email }}</p>
      <p><strong>Phone:</strong> {{ user.phone }}</p>
      <p><strong>User Type:</strong> {{ user.userType }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EModificationType } from '../globals';

export default Vue.extend({
  async created() {
    try {
      await this.fetchUser();
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  data() {
    return {
      user: null,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    }
  },
  methods: {
    async fetchUser() {
      try {
        this.user = await this.$store.dispatch(EModificationType.FETCH_USER_BY_ID, {userId: this.id});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.user-view-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.user-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

.back-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.back-btn:hover {
  background-color: #0056b3;
}
</style>
