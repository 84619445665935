<template>
  <div class="dashboard">
    <div class="sidebar">
      <div class="logo-container">
        <img src="/logo.png" alt="Animal Health Monitoring System Logo" class="logo">
        <h2>ANIMAL HEALTH MONITORING SYSTEM</h2>
      </div>
      <ul>
        <li>
          <router-link to="/metrics">
            <div class="menu-item">
              <h3>Dashboard</h3>
              <p>View metrics</p>
            </div>
          </router-link>
        </li>
        <li v-show="shouldShow([userType.farmer, userType.admin, userType.farmManager, userType.vet])">
          <router-link to="/animals">
            <div class="menu-item">
              <h3>Animals</h3>
              <p>Manage your livestock</p>
            </div>
          </router-link>
        </li>
        <li v-show="shouldShow([userType.admin])">
          <router-link to="/vendors">
            <div class="menu-item">
              <h3>Vendors</h3>
              <p>Manage vendors</p>
            </div>
          </router-link>
        </li>
        <li v-show="shouldShow([userType.admin])">
          <router-link to="/sensors">
            <div class="menu-item">
              <h3>Sensors</h3>
              <p>Manage sensors</p>
            </div>
          </router-link>
        </li>
        <li v-show="shouldShow([userType.admin, userType.vet, userType.farmer, userType.farmManager])">
          <router-link to="/health-alerts">
            <div class="menu-item">
              <h3>Health Alerts</h3>
              <p>View health alerts</p>
            </div>
          </router-link>
        </li>
        <li v-show="shouldShow([userType.admin])">
          <router-link to="/users">
            <div class="menu-item">
              <h3>Users</h3>
              <p>Manage users</p>
            </div>
          </router-link>
        </li>
        <li>
          <div @click="logout" class="menu-item-logout">
            <h3>Logout</h3>
            <p>Exit your session</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="main-content">
      <div class="dashboard-content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, EGettersType, EModificationType, EUserType} from '../globals';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'DashboardView',
  data() {
    return {
      userType: {
        farmer: EUserType.FARMER,
        admin: EUserType.ADMIN,
        vet: EUserType.VET,
        farmManager: EUserType.FARM_MANAGER
      }
    };
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_USER_ROLE
    ])
  },
  methods: {
    shouldShow: function (listOfUserType: Array<string>) {
      return listOfUserType.indexOf(this.userRole) > -1;
    },
    logout: async function () {
      try {
        await this.$store.dispatch(EModificationType.LOG_OUT);
        await this.$router.replace('/login'); // Redirect to login after logout
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.dashboard {
  display: flex;
  min-height: 100vh;
}

.sidebar {
  background-color: #E8F4FA;
  padding: 10px;
  color: white;
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  text-align: center;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 40px;
  height: auto;
  margin-bottom: 5px;
}

.sidebar h2 {
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar a {
  color: white;
  text-decoration: none;
  display: block;
}

.menu-item {
  background-color: #fff;
  color: #2dce89;
  border-radius: 8px;
  border: 2px solid #666666;
  padding: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.menu-item-logout {
  background-color: #fff;
  color: #e23232;
  border-radius: 8px;
  border: 2px solid #666666;
  padding: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.menu-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.menu-item h3 {
  margin: 0;
  font-size: 18px;
}

.menu-item p {
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
}

.menu-item-logout h3 {
  margin: 0;
  font-size: 18px;
}

.menu-item-logout p {
  margin: 5px 0 0;
  color: #666;
  font-size: 12px;
}

.main-content {
  flex-grow: 1;
  background-color: #FFFFFF;
  margin-left: 270px;
  padding: 20px;
}

.dashboard-content {
  margin-top: 20px;
}
</style>

