<template>
  <div>
    <h1>HealthAlerts</h1>
    <HealthAlertList />
  </div>
</template>

<script>
import HealthAlertList from '../components/HealthAlertList.vue';

export default {
  name: 'HealthAlertsView',
  components: {
    HealthAlertList,
  },
};
</script>