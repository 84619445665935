// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyD_glS4aJPlA0ZA1GW9i3dtzKJHOi2L-bY",
  authDomain: "bw-gov-livestock-hms.firebaseapp.com",
  projectId: "bw-gov-livestock-hms",
  storageBucket: "bw-gov-livestock-hms.appspot.com",
  messagingSenderId: "536494300923",
  appId: "1:536494300923:web:f847c9efa15e9357361a5e",
  measurementId: "G-KTFDNR28WF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Messaging and check if it's supported
let messaging: any = null;
if (await isSupported()) {
  messaging = getMessaging(app);
} else {
  console.warn('Firebase messaging is not supported in this environment.');
}

export { messaging };