<template>
  <div class="report-form-container">
    <h2>Add New Report For EID: {{ animal.eid }}</h2>

    <div class="animal-details">
      <p><strong>Gender:</strong> {{ animal.sex }}</p>
      <p><strong>Current Weight (kg):</strong> {{ animal.weights && animal.weights.length > 0 ? animal.weights[animal.weights.length - 1].weight : 'N/A' }}</p>
      <p><strong>Date of Birth:</strong> {{ new Date(animal.ModisarAnimalDataDump.DateOfBirth).toLocaleDateString() }}</p>
    </div>

    <form @submit.prevent="handleSubmit" class="report-form">
      <div class="form-group">
        <label for="reportType">Report Type:</label>
        <select id="reportType" v-model="form.reportType" required>
          <option value="TREATMENT_REPORT">Treatment Report</option>
          <option value="VACCINATION_REPORT">Vaccination Report</option>
          <option value="ROUTINE_CHECK_REPORT">Routine Check Report</option>
        </select>
      </div>

      <div v-if="form.reportType === 'ROUTINE_CHECK_REPORT'" class="form-group">
        <label for="health-status">Health Status:</label>
        <select id="health-status" v-model="form.healthStatus">
          <option value="HEALTHY">HEALTHY</option>
          <option value="AILING">SICK</option>
        </select>
      </div>

      <div v-if="form.reportType === 'VACCINATION_REPORT'" class="form-group">
        <label for="vaccine">Vaccine:</label>
        <select id="vaccine" v-model="form.vaccineId" required>
          <option v-for="vaccine in vaccines" :key="vaccine.id" :value="vaccine.id">
            {{ vaccine.CommonName }}
          </option>
        </select>
      </div>

      <div v-if="form.reportType === 'TREATMENT_REPORT'" class="form-group">
        <label for="disease">Diagnosis:</label>
        <select id="disease" v-model="form.diseaseId" required>
          <option v-for="disease in diseases" :key="disease.id" :value="disease.id">
            {{ disease.DiseaseDescription }}
          </option>
        </select>
      </div>

      <div v-if="form.reportType === 'TREATMENT_REPORT'" class="form-group">
        <label for="treatment">Treatment:</label>
        <select id="treatment" v-model="form.treatmentId" required>
          <option v-for="treatment in treatments" :key="treatment.id" :value="treatment.id">
            {{ treatment.TreatmentTypeDescription }}
          </option>
        </select>
      </div>

      <div class="form-group">
        <label for="notes">Notes:</label>
        <textarea id="notes" v-model="form.notes" required rows="5"></textarea>
      </div>
      <button type="submit">Add Report</button>
    </form>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { EReportType, EModificationType, DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS, ESortOrderType, EVaccineSortType, EDiseaseSortType, ETreatmentSortType, EGettersType } from '../globals';
const LIST_LIMIT = 100;

interface IWeight {
  weight: number;
  recordedDate: string;
}

export default Vue.extend({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      EGettersType.GET_VACCINES,
      EGettersType.GET_DISEASES,
      EGettersType.GET_TREATMENTS
    ])
  },
  data() {
    return {
      animal: {
        id: '',
        sex: '',
        eid: '',
        healthStatus: '',
        weights: [] as IWeight[],
        ModisarAnimalDataDump: {
          AnimalSpeciesID: 0,
          DateOfBirth: ''
        }
      },
      form: {
        vaccineId: '',
        diseaseId: '',
        treatmentId: '',
        healthStatus: '',
        notes: '',
        reportType: EReportType.ROUTINE_CHECK_REPORT
      }
    };
  },
  created: async function () {
    try {
      await this.fetchAnimal();
      this.form.healthStatus = this.animal.healthStatus;
      await Promise.all([this.fetchVaccines(), this.fetchDiseases(), this.fetchTreatments()]);
      console.log("Vaccines: ", this.vaccines);
      console.log("Diseases: ", this.diseases);
      console.log("Treatments: ", this.treatments); 
    } catch (e) {
      if (e instanceof Error) {
        console.log('Show on interface: ', e);
      } else {
        console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
      }
    }
  },
  methods: {
    async fetchAnimal() {
      try {
        this.animal = await this.$store.dispatch(EModificationType.FETCH_ANIMAL_BY_ID, {animalId: this.id});
        console.log("Animal: ", this.animal)
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    async fetchVaccines() {
      try {
        await this.$store.dispatch(EModificationType.FETCH_VACCINES, {skip: 0, sortOrder: ESortOrderType.ASC, sortBy: EVaccineSortType.DATE, limit: LIST_LIMIT});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    async fetchDiseases() {
      try {
        await this.$store.dispatch(EModificationType.FETCH_DISEASES, {skip: 0, AnimalSpeciesID: this.animal.ModisarAnimalDataDump.AnimalSpeciesID, sortOrder: ESortOrderType.ASC, sortBy: EDiseaseSortType.DATE, limit: LIST_LIMIT});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    async fetchTreatments() {
      try {
        await this.$store.dispatch(EModificationType.FETCH_TREATMENTS, {skip: 0, AnimalSpeciesID: this.animal.ModisarAnimalDataDump.AnimalSpeciesID, sortOrder: ESortOrderType.ASC, sortBy: ETreatmentSortType.DATE, limit: LIST_LIMIT});
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    },
    handleSubmit: async function() {
      try {
        // Call server to add report
        if (this.form.reportType === EReportType.TREATMENT_REPORT as any) {
          await this.$store.dispatch(EModificationType.CREATE_TREATMENT_REPORT, {
            notes: this.form.notes,
            animalId: this.id,
            treatmentId: this.form.treatmentId,
            diseaseId: this.form.diseaseId
          });
        } else if (this.form.reportType === EReportType.VACCINATION_REPORT as any) {
          await this.$store.dispatch(EModificationType.CREATE_VACCINATION_REPORT, {
            notes: this.form.notes,
            animalId: this.id,
            vaccineId: this.form.vaccineId
          });
        } else if (this.form.reportType === EReportType.ROUTINE_CHECK_REPORT as any) {
          await this.$store.dispatch(EModificationType.CREATE_ROUTINE_CHECK_REPORT, {
            notes: this.form.notes,
            animalId: this.id,
            healthStatus: this.form.healthStatus
          });
        }
        // Redirect to report list
        this.$router.push({ name: 'reports', params: { id: this.id } });
      } catch (e) {
        if (e instanceof Error) {
          console.log('Show on interface: ', e);
        } else {
          console.log('Show on interface: ', DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS);
        }
      }
    }
  }
});
</script>

<style scoped>
.report-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.report-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  margin-bottom: 0.5rem;
}

input,
select,
textarea {
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

button {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 20px; /* Provides some space from the form above */
}

.back-btn {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: #5a6268;
}

.animal-details {
  padding: 10px;
  border: 2px solid #666666;
  border-radius: 4px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
}

</style>
