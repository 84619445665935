export const NETWORK_ERROR_MESSAGE = "Network request failed";
export const UNEXPECTED_ERROR_PREFIX = "Unexpected token";
export const SERVER_UNREACHABLE = "The server could not be reached at this time. Please try again";
export const DEFAULT_ERROR_MESSAGE_WITH_ENGINEERS = "Something went wrong. Our engineers are looking into the problem, and we apologise for any inconvenience caused";
export const DEFAULT_LIST_LIMIT = 20;

// const baseURL = "http://localhost:8889";
// const baseURL = "https://animalhealth-uat.gov.bw/api";
const baseURL = "https://lmsserv.auctiondev.xyz";

export const SERVICE_URLS: {[key: string]: string} = {
  lmsAuthURI: process.env.lms_AUTH_URI || `${baseURL}/auth`,
  lmsOpenURI: process.env.lms_OPEN_URI || `${baseURL}/open`,
  lmsAppURI: process.env.lms_APP_URI || `${baseURL}/app`,
}

// Vital bounds for different species
export const vitalBounds: any = {
  BOVINE: {
    WEIGHT: { upper: 1000, lower: 25 },
    TEMPERATURE: { upper: 39.5, lower: 38.5 },
    PH: { upper: 6.2, lower: 6.0 },
    HEART_RATE: { upper: 80, lower: 40 },
    BLOOD_OXYGEN_LEVEL: { upper: 100, lower: 95 },
  },
  OVINE: {
    WEIGHT: { upper: 100, lower: 25 },
    TEMPERATURE: { upper: 40.0, lower: 38.3 },
    PH: { upper: 6.5, lower: 6.0 },
    HEART_RATE: { upper: 90, lower: 60 },
    BLOOD_OXYGEN_LEVEL: { upper: 100, lower: 95 },
  },
  CAPRINE: {
    WEIGHT: { upper: 100, lower: 25 },
    TEMPERATURE: { upper: 40.5, lower: 38.5 },
    PH: { upper: 6.5, lower: 6.0 },
    HEART_RATE: { upper: 90, lower: 70 },
    BLOOD_OXYGEN_LEVEL: { upper: 100, lower: 95 },
  },
  EQUINE: {
    WEIGHT: { upper: 1000, lower: 25 },
    TEMPERATURE: { upper: 38.5, lower: 37.5 },
    PH: { upper: 7.4, lower: 7.2 },
    HEART_RATE: { upper: 45, lower: 28 },
    BLOOD_OXYGEN_LEVEL: { upper: 100, lower: 95 },
  },
  PORCINE: {
    WEIGHT: { upper: 1000, lower: 25 },
    TEMPERATURE: { upper: 39.5, lower: 38.0 },
    PH: { upper: 7.5, lower: 7.2 },
    HEART_RATE: { upper: 100, lower: 70 },
    BLOOD_OXYGEN_LEVEL: { upper: 100, lower: 95 },
  },
};

export enum EModificationType {
  SET_FCM_TOKEN = "SET_FCM_TOKEN",
  GET_FCM_TOKEN = "GET_FCM_TOKEN",
  SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT = "SET_FIREBASE_DEVICE_TOKEN_ON_OWN_ACCOUNT",
  CREATE_BREED = "CREATE_BREED",
  CREATE_VENDOR = "CREATE_VENDOR",
  FETCH_HEALTH_ALERTS = "FETCH_HEALTH_ALERTS",
  MARK_HEALTH_ALERT_AS_READ = "MARK_HEALTH_ALERT_AS_READ",
  UPDATE_HEALTH_STATUS = "UPDATE_HEALTH_STATUS",
  UPDATE_GESTATION_STATUS = "UPDATE_GESTATION_STATUS",
  UPDATE_TREATMENT_STATUS = "UPDATE_TREATMENT_STATUS",
  CREATE_ANIMAL = "CREATE_ANIMAL",
  CREATE_SENSOR = "CREATE_SENSOR",
  CREATE_TAG = "CREATE_TAG",
  CREATE_TREATMENT_REPORT = "CREATE_TREATMENT_REPORT",
  CREATE_VACCINATION_REPORT = "CREATE_VACCINATION_REPORT",
  CREATE_ROUTINE_CHECK_REPORT = "CREATE_ROUTINE_CHECK_REPORT",
  SET_USER = "SET_USER",
  FETCH_ANIMAL_STATS = "FETCH_ANIMAL_STATS",
  UPLOAD_DRF = "UPLOAD_DRF",
  REMOVE_DRF = "REMOVE_DRF",
  SET_ANIMALS = "SET_ANIMALS",
  SET_VENDORS = "SET_VENDORS",
  SET_SENSORS = "SET_SENSORS",
  SET_USERS = "SET_USERS",
  LOG_OUT = "LOG_OUT",
  FETCH_OWN_ACCOUNT = "FETCH_OWN_ACCOUNT",
  FETCH_ANIMALS = "FETCH_ANIMALS",
  FETCH_SENSORS = "FETCH_SENSORS",
  FETCH_HOURLY_VITALS = "FETCH_HOURLY_VITALS",
  FETCH_DAILY_VITALS = "FETCH_DAILY_VITALS",
  CREATE_USER = "CREATE_USER",
  FETCH_USERS = "FETCH_USERS",
  FETCH_VENDORS = "FETCH_VENDORS",
  FETCH_VITALS = "FETCH_VITALS",
  FETCH_REPORTS = "FETCH_REPORTS",
  HEALTH_ALERTS = "HEALTH_ALERTS",
  SEND_PASSWORD_RESET_LINK = "SEND_PASSWORD_RESET_LINK",
  RESET_PASSWORD = "RESET_PASSWORD",
  LOG_IN_WITH_LOCAL_AUTH = "LOG_IN_WITH_LOCAL_AUTH",
  SET_IS_NETWORK_ERROR_OR_TIMEOUT = "SET_IS_NETWORK_ERROR_OR_TIMEOUT",
  SET_IS_AUTHENTICATED = "SET_IS_AUTHENTICATED",
  SET_HEALTH_ALERTS = "SET_HEALTH_ALERTS",
  APPEND_HEALTH_ALERTS = "APPEND_HEALTH_ALERTS",
  FETCH_BREED_BY_ID = "FETCH_BREED_BY_ID",
  FETCH_ANIMAL_BY_ID = "FETCH_ANIMAL_BY_ID",
  FETCH_VENDOR_BY_ID = "FETCH_VENDOR_BY_ID",
  FETCH_SENSOR_BY_ID = "FETCH_SENSOR_BY_ID",
  FETCH_TAG_BY_ID = "FETCH_TAG_BY_ID",
  FETCH_USER_BY_ID = "FETCH_USER_BY_ID",
  FETCH_NUMBER_OF_OFFSPRING = "FETCH_NUMBER_OF_OFFSPRING",
  FETCH_ANIMAL_ANCESTRY = "FETCH_ANIMAL_ANCESTRY",
  LINK_SENSOR_TO_ANIMAL_USING_EID = "LINK_SENSOR_TO_ANIMAL_USING_EID",
  UPDATE_ANIMAL_WEIGHT = "UPDATE_ANIMAL_WEIGHT",
  FETCH_VACCINES = "FETCH_VACCINES",
  FETCH_DISEASES = "FETCH_DISEASES",
  FETCH_TREATMENTS = "FETCH_TREATMENTS",
  SET_VACCINES = "SET_VACCINES",
  SET_DISEASES = "SET_DISEASES",
  SET_TREATMENTS = "SET_TREATMENTS"
}

export enum EGettersType {
  GET_USER = "user",
  GET_IS_AUTHENTICATED = "isAuthenticated",
  GET_USER_ROLE = "userRole",
  GET_VENDORS = "vendors",
  GET_VACCINES = "vaccines",
  GET_DISEASES = "diseases",
  GET_TREATMENTS = "treatments",
  GET_SENSORS = "sensors",
  GET_ANIMALS = "animals",
  GET_USERS = "users",
  GET_HEALTH_ALERTS = "healthAlerts",
  GET_FCM_TOKEN = "fcmToken"
}

export enum ESortOrderType {
  ASC = "ASC",
  DESC = "DESC",
  asc = "asc",
  desc = "desc"
}

export enum EBreedSortType {
  NAME = "NAME"
}

export enum EAnimalSortType {
  DOB = "DOB"
}

export enum ETreatmentSortType {
  DATE = "DATE"
}

export enum EDiseaseSortType {
  DATE = "DATE"
}

export enum EVaccineSortType {
  DATE = "DATE"
}

export enum EReportSortType {
  DATE = "DATE"
}

export enum ETagSortType {
  DATE = "DATE"
}

export enum ESensorSortType {
  DATE = "DATE"
}

export enum EVendorSortType {
  DATE = "DATE"
}

export enum EUserSortType {
  DATE = "DATE"
}

export enum EVitalSortType {
  DATE = "DATE"
}

export enum EHealthAlertSortType {
  DATE = "DATE"
}

export enum EVitalType {
  ALL = "ALL",
  TEMPERATURE = "TEMPERATURE",
  PH = "PH",
  HEART_RATE = "HEART_RATE",
  BLOOD_OXYGEN_LEVEL = "BLOOD_OXYGEN_LEVEL",
}

export enum EFetchVitalFrequency {
  HOURLY = "HOURLY",
  DAILY = "DAILY"
}

export enum EReportType {
  ALL = "ALL",
  TREATMENT_REPORT = "TREATMENT_REPORT",
  VACCINATION_REPORT = "VACCINATION_REPORT",
  ROUTINE_CHECK_REPORT = "ROUTINE_CHECK_REPORT"
}

export enum EUserType {
  ALL = "ALL",
  FARMER = "FARMER",
  FARM_MANAGER = "FARM_MANAGER",
  ADMIN = "ADMIN",
  VET = "VET",
}

export enum EAnimalSpecies {
  ALL = "ALL",
  BOVINE = "BOVINE",
  EQUINE = "EQUINE",
  CAPRINE = "CAPRINE",
  OVINE = "OVINE",
  PORCINE = "PORCINE"
}

export enum EHealthStatus {
  ALL = "ALL",
  HEALTHY = "HEALTHY",
  AILING = "AILING",
  DECEASED = "DECEASED"
}

export enum ETagStatus {
  ALL = "ALL",
  DISPOSED = "DISPOSED",
  ASSIGNED = "ASSIGNED",
  UNASSIGNED = "UNASSIGNED"
}

export enum EAnimalSexType {
  ALL = "ALL",
  MALE = "MALE",
  FEMALE = "FEMALE"
}

export enum EGestationStatus {
  ALL = "ALL",
  TRUE = "true",
  FALSE = "false"
}

export enum EGestationDurationDays {
  BOVINE = 280,
  OVINE = 150,
  CAPRINE = 150,
  EQUINE = 340,
  PORCINE = 114
}
