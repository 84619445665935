<template>
  <div class="location-widget">
    <div class="map-container" ref="mapContainer"></div>
    <p><strong>Coordinates:</strong> 26° 35' 8.9628'' S, 21° 48' 57.654'' E</p>
  </div>
</template>

<script>
import L from 'leaflet';

export default {
  name: 'LocationWidget',
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      const coordinates = [-26.5858230, 21.8160150];
      const map = L.map(this.$refs.mapContainer, {
        center: coordinates,
        zoom: 13
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '© OpenStreetMap contributors'
      }).addTo(map);

      // Adding a marker to the map at the specific coordinates
      L.marker(coordinates).addTo(map)
          .bindPopup('Lobu Farm, BW') // Popup to show when marker is clicked
          .openPopup();
    }
  }
};
</script>

<style scoped>
.location-widget {
  text-align: center;
  padding: 20px;
}

.map-container {
  height: 300px;
  width: 100%;
  margin-bottom: 20px;
}
</style>
