<template>
  <div class="login-page">
    <div class="login-left">
      <div class="image-container"></div>
    </div>
    <div class="login-right">
      <div class="login-container">
        <img src="/logo.png" alt="ministry-of-agriculture" class="logo">
        <h2>ANIMAL HEALTH MONITORING SYSTEM</h2>
        <h4>Reset Password</h4>
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label for="password">New Password:</label>
            <div class="password-wrapper">
              <input :type="passwordFieldType" id="password" v-model="password" @input="validatePassword" required placeholder="Enter your new password">
              <span class="toggle-password" @click="togglePasswordVisibility">
                <i :class="passwordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              </span>
            </div>
            <p v-if="passwordStrengthMessage" :class="passwordStrengthClass">{{ passwordStrengthMessage }}</p>
          </div>
          <div class="form-group">
            <label for="confirmPassword">Confirm Password:</label>
            <div class="password-wrapper">
              <input :type="confirmPasswordFieldType" id="confirmPassword" v-model="confirmPassword" required placeholder="Confirm your new password">
              <span class="toggle-password" @click="toggleConfirmPasswordVisibility">
                <i :class="confirmPasswordFieldType === 'password' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              </span>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="login-button">Reset Password</button>
          </div>
        </form>
        <p v-if="message" class="message">{{ message }}</p>
        <p v-if="error" class="error-message">{{ error }}</p>
      </div>
      <footer class="footer">
        <p>Animal Health Monitoring System © 2024 Government of Botswana. All rights reserved.</p>
        <p>Developed by Pyxias, Inc.</p>
      </footer>
    </div>
  </div>
</template>

<script>
import { EModificationType } from "@/globals";

export default {
  props: {
    token: {
      type: String,
      required: true
    }
  }, // Accept token as a prop
  data() {
    return {
      password: '',
      confirmPassword: '',
      message: '',
      error: '',
      passwordStrengthMessage: '',
      passwordStrengthClass: '',
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
    };
  },
  methods: {
    validatePassword() {
      const password = this.password;
      let strengthMessage = '';
      let strengthClass = '';

      if (password.length < 8) {
        strengthMessage = 'Password is too short (min 8 characters).';
        strengthClass = 'error-message';
      } else if (!/[A-Z]/.test(password)) {
        strengthMessage = 'Include at least one uppercase letter.';
        strengthClass = 'error-message';
      } else if (!/[a-z]/.test(password)) {
        strengthMessage = 'Include at least one lowercase letter.';
        strengthClass = 'error-message';
      } else if (!/[0-9]/.test(password)) {
        strengthMessage = 'Include at least one number.';
        strengthClass = 'error-message';
      } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        strengthMessage = 'Include at least one special character (~`! @#$%^&*()-_)';
        strengthClass = 'error-message';
      } else {
        strengthMessage = 'Strong password!';
        strengthClass = 'success-message';
      }

      this.passwordStrengthMessage = strengthMessage;
      this.passwordStrengthClass = strengthClass;
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    toggleConfirmPasswordVisibility() {
      this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
    },
    async handleSubmit() {
      if (this.password !== this.confirmPassword) {
        this.error = 'Passwords do not match';
        return;
      }
      if (this.passwordStrengthClass === 'error-message') {
        this.error = 'Please choose a stronger password.';
        return;
      }
      try {
        await this.$store.dispatch(EModificationType.RESET_PASSWORD, {
          token: this.token,
          newPassword: this.password
        });
        this.message = 'Password has been reset successfully';
        this.error = '';
        setTimeout(() => {
          this.$router.push('/login');
        }, 2000);
      } catch (error) {
        this.error = 'Error resetting password';
        this.message = '';
      }
    }
  }
};
</script>

<style scoped>
.login-page {
  display: flex;
  height: 100vh;
}

.login-left {
  flex: 1;
  background: url('/src/assets/sheep.jpg') no-repeat center center;
  background-size: cover;
}

.login-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #E8F4FA;
  position: relative;
}

.login-container {
  max-width: 400px;
  margin: 100px 0px 0px 0px;
  padding: 20px;
  border: 2px solid #666666;
  background-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: auto;
}

.logo {
  width: 100px;
  margin-bottom: 20px;
}

h2 {
  color: #333;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.password-wrapper {
  position: relative;
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #666;
}

input {
  width: 100%;
  padding: 10px;
  padding-right: 35px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-actions {
  margin-top: 20px;
  text-align: center;
}

.login-button {
  width: 100%;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}

.message {
  color: #28a745;
  text-align: center;
  margin-top: 12px;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 12px;
}

.success-message {
  color: #28a745;
  text-align: center;
  margin-top: 12px;
}

.footer {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  background-color: #f8f8f8;
  border-top: 2px solid #666666;
  margin-top: auto;
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #666;
  position: relative;
}

/* Icons (FontAwesome) */
.fa-eye, .fa-eye-slash {
  font-size: 18px;
  color: #666;
}
</style>
